<template>
  <v-container fluid>
    <v-card>
      <v-card-text>
        <v-tabs>
          <v-tab href="#victim"> Desarmonizados </v-tab>
          <v-tab-item value="victim">
            <br />
            <ListVictimTracking />
          </v-tab-item>

          <v-tab href="#victimize"> Desarmonizantes </v-tab>
          <v-tab-item value="victimize">
            <br />
            <ListPenaltiesTracking />
          </v-tab-item>
        </v-tabs>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import ListPenaltiesTracking from "./ValueReferences/PenaltiesTracking/ListPenaltiesTracking";
import ListVictimTracking from "./ValueReferences/VictimTracking/ListVictimTracking";

export default {
  name: "Tracking.vue",
  components: {ListPenaltiesTracking, ListVictimTracking},
};
</script>

<style scoped></style>
