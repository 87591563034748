<template>
  <v-container fluid>
    <template>
      <v-breadcrumbs>
        <template>
          <v-breadcrumbs-item>
            <h3>PLANEACIÓN</h3>
          </v-breadcrumbs-item>
          <v-breadcrumbs-item>
            <v-icon color="primary">mdi-home</v-icon>
          </v-breadcrumbs-item>
          <v-breadcrumbs-item>CATEGORÍAS</v-breadcrumbs-item>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            dark
            rounded
            class="mb-2"
            :to="{
              name: 'planning.createCategories'
            }"
          >
            Nueva Categoría
          </v-btn>
        </template>
      </v-breadcrumbs>
    </template>
    <v-card>
      <v-card-title>
        <v-row>
          <v-col cols="12" sm="12" lg="4" md="4">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Buscar"
              single-line
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :footer-props="{
            'items-per-page-text': 'Filas por página',
            'items-per-page-options': [5,10,15]

          }"
          :headers="headers"
          :items="events"
          :items-per-page="15"
          :search="search"
          item-key="id"
        >
          <template v-slot:item.action="{ item }">
            <v-menu left bottom>
              <template v-slot:activator="{ on }">
                <v-btn icon v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="editCase(item.id)">
                  <v-list-item-title>
                    <v-icon class="mr-2" small>mdi-circle-edit-outline</v-icon>
                    Editar
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { listCategories } from "@/services/Planning";
import Swal from "sweetalert2";

export default {
  name: "ListCategories",
  props : {
  },
  data() {
    return {
      municipalities: [],
      territories: [],
      sidewalks: [],
      events: [],
      search: "",
      headers: [
        {
          text: "ID",
          align: "start",
          sortable: true,
          value: "id",
        },
        { text: "NOMBRE", value: "name" },
        { text: "MANDATO", value: "mandate" },
        { text: "ACCIÓN", value: "action", sortable: false },
      ],
    };
  },
  methods: {
    editCase(id) {

      this.$router.push({
        name: "planning.editCategories",
        params: {
          id: id
        },
      });
    },
  },
  async created() {
    /**
     * [ obtiene la lista de categorias]
     * @param {[this.events]} eventos [array de eventos]
     */
    try {
      this.showLoader();
      let listEventsResponse = (await listCategories())?.data;
      this.events = listEventsResponse;
      this.hideLoader();
    } catch (err) {
      this.hideLoader();
      console.error(err);
      Swal.fire(
        "Error",
        "ha ocurrido un error al procesar la solicitud",
        "error"
      );
    }
  },
};
</script>

<style scoped></style>
