<template>
  <v-card>
    <v-tabs vertical>
      <v-subheader>Valores referencia</v-subheader>
      <v-divider></v-divider>
      <v-tab>
        <v-icon left size="medium">fas fa-user-check</v-icon>
        Categorías
      </v-tab>
      <v-tab>
        <v-icon left size="medium">fas fa-user-tag</v-icon>
        Sistema responsable
      </v-tab>
      <v-tab>
        <v-icon left size="medium">fas fa-user-tag</v-icon>
        Fuente financiaciòn
      </v-tab>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <ListCategories/>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <listEntityResponsable/>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <ListFundingSource/>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <ListCorrectionType />
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <Tracking />
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs>
  </v-card>
</template>
<script>
import ListCategories from "@/views/Planning/components/ValueReferences/Categories/ListCategories";
import ListEntityResponsable from "@/views/Planning/components/ValueReferences/ResponsableSystem/ListEntityResponsable";
import ListFundingSource from "@/views/Planning/components/ValueReferences/FundingSource/ListFundingSource";
import ListCorrectionType from "@/views/SelfJustice/components/ValueReferences/CorrectionType/ListCorrectionType";
import Tracking from "@/views/SelfJustice/components/Tracking";

export default {
  components: {
    ListCategories,
    ListEntityResponsable,
    ListFundingSource,
    ListCorrectionType,
    Tracking,
  },
  data() {
    return {};
  },
  props : {
    isFemale: {
      type: String,
      default: 'false'
    }
  },
  computed: {},
  beforeMount() {},
};
</script>
<style scoped>
.v-tab {
  justify-content: start !important;
}
</style>
